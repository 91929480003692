import merge from 'deepmerge'

const emptyTarget = (value) => (Array.isArray(value) ? [] : {})
const clone = (value, options) => merge(emptyTarget(value), value, options)

export const arrayMerge = (target, source, options) => {
  const destination = target.slice()
  source.forEach(function (e, i) {
    if (typeof destination[i] === 'undefined') {
      const cloneRequested = options.clone !== false
      const shouldClone = cloneRequested && options.isMergeableObject(e)
      destination[i] = shouldClone ? clone(e, options) : e
    } else if (options.isMergeableObject(e)) {
      destination[i] = merge(target[i], e, options)
    } else if (target.indexOf(e) === -1) {
      destination.push(e)
    }
  })
  return destination
}

/* arrayGroupBy Example usage:

const pets = [
  {type:"Dog", name:"Spot"},
  {type:"Cat", name:"Tiger"},
  {type:"Dog", name:"Rover"},
  {type:"Cat", name:"Leo"}
];

const grouped = groupBy(pets, pet => pet.type);

console.log(grouped.get("Dog")); // -> [{type:"Dog", name:"Spot"}, {type:"Dog", name:"Rover"}]
console.log(grouped.get("Cat")); // -> [{type:"Cat", name:"Tiger"}, {type:"Cat", name:"Leo"}]

*/

export const arrayGroupBy = (list, keyGetter) => {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

/*
indxToArr accept an indexed collection
and returns an array
*/
export const indexToArr = (index) => {
  if (index) {
    const collection = Object.keys(index)
    if (collection.length) {
      return collection.map((i) => index[i])
    } else {
      return []
    }
  } else {
    return []
  }
}
export const dataToArr = (index, classes) => {
  const lines = Object.keys(index)
  if (lines.length) {
    return lines.map((l) => {
      const className = classes && classes[l] ? classes[l] : ''
      return Object.assign({ className }, index[l])
    })
  } else {
    return []
  }
}

export const colsToArr = (index, detail) => {
  const cols = Object.keys(index)
  let links = ''
  if (cols.length) {
    const columns = cols.reduce((cols, c) => {
      let o = index[c]
      if (c !== '0') o.align = 'center'
      // if (c === '0' && !detail) o.fixed = 'left'
      // if (o.width === null && !detail) o.width = 130
      o.key = c
      if (o.title === 'link') {
        links = c
      } else {
        cols.push(o)
      }
      return cols
    }, [])
    return { columns, links }
  } else {
    return { columns: [], links }
  }
}

export const dataToCountries = (index, countries) => {
  if (index) {
    const countriesWithValues = Object.keys(index)
    if (countriesWithValues.length) {
      return countriesWithValues
        .map((c) => index[c])
        .reduce((cnt, i) => {
          if (i['0']) {
            const countryLoc = countries.find((cl) => cl.code === i['0'])
            if (countryLoc && countryLoc.latitude && countryLoc.longitude) {
              cnt.push({
                code: i['0'],
                name: countryLoc.name,
                lat: countryLoc.latitude,
                lon: countryLoc.longitude,
                rad: i['1'] || 0,
                link: i['2'] || ''
              })
            }
          }
          return cnt
        }, [])
    } else {
      return []
    }
  } else {
    return []
  }
}

export const flatten = (arr) => {
  return arr.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [])
}
