<template>
  <div>
    <Card v-if="url !== 'TC String'">
      <span slot="title">Request</span>
      <div slot="extra">
        <Button
          size="small"
          @click="e => copyJSON(e, parameters)"
        >
          Copy
          <span class="far fa-copy" />
        </Button>
      </div>
      <div slot="default">
        <Input
          class="request-item"
          :value="url"
          :readonly="true"
        />
        <div class="request-item">
          <Table
            :data="_parameters"
            :columns="parametersTableColumns"
          />
        </div>
      </div>
    </Card>
    <Card>
      <span slot="title">Response</span>
      <div slot="extra">
        <Button
          size="small"
          @click="e => copyJSON(e, response)"
        >
          Copy
          <span class="far fa-copy" />
        </Button>
      </div>
      <div
        class="request-item"
        slot="default"
      >
        <vue-json-pretty
          :data="response"
          :deep="1"
        />
      </div>
    </Card>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'

export default {
  name: 'RequestContainer',
  components: {
    VueJsonPretty
  },
  props: {
    requestIdx: {
      type: Number,
      default: () => 0,
      required: true
    },
    url: {
      type: String,
      default: () => ''
    },
    parameters: {
      type: Object,
      default: () => {}
    },
    response: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      parametersTableColumns: [
        {
          title: 'Key',
          key: 'key'
        },
        {
          title: 'Value',
          key: 'value'
        }
      ]
    }
  },
  methods: {
    copyJSON (event, json) {
      const responseString = JSON.stringify(json)
      const customCopyEventListener = e => {
        e.clipboardData.setData('text/plain', responseString)
        e.preventDefault()
        document.removeEventListener('copy', customCopyEventListener, false)
      }

      document.addEventListener('copy', customCopyEventListener, false)
      event.stopPropagation()
      document.execCommand('copy')
      this.$Message.success({
        content: 'JSON successfully copied to clipboard!',
        duration: 3
      })
    }
  },
  computed: {
    _parameters () {
      return this.parameters ? Object.entries(this.parameters).map(parameter => ({
        key: parameter[0],
        value: parameter[1]
      })) : []
    }
  }
}
</script>

<style lang="scss">
.request-item {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
