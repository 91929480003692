var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Drawer', {
    staticClass: "focus-panel",
    attrs: {
      "mask-style": {
        background: 'rgba(180, 183, 182, 0.6)'
      },
      "width": "85%",
      "value": _vm.show === this.hash(JSON.stringify(_vm.params) + _vm.namespace)
    },
    on: {
      "on-visible-change": _vm.handleVisibleChange
    }
  }, [_c('div', {
    staticClass: "drawer-header"
  }, [_c('h3', [_vm._v(_vm._s(_vm.description.title) + " ")]), _c('div', {
    staticClass: "widget-about"
  }, [_c('div', [_vm._v(_vm._s(_vm.description.desc))])]), _c('Alert', {
    staticStyle: {
      "display": "block",
      "width": "100%"
    },
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" The table may contains more data than the clicked widget because we are displaying more contextual data (eg: in widgets, trackers are not count by distinc type [pixel, indexDB, localsorage..] but they are in table details ")]), _c('div', {
    staticClass: "widget-metadata"
  }, [_c('br'), _c('div', [_c('p', [_vm._v(" " + _vm._s(this.$t('global.report')) + " "), _c('strong', [_vm._v(_vm._s(_vm.scopeSelectedProperty.displayName))]), _vm._v(" " + _vm._s(this.$t('global.from')) + " "), _c('strong', [_vm._v(_vm._s(_vm.scopeSelectedDaterange[0]))]), _vm._v(" " + _vm._s(this.$t('global.to')) + " "), _c('strong', [_vm._v(_vm._s(_vm.scopeSelectedDaterange[1]))])])])])], 1), _c('Divider', {
    attrs: {
      "dashed": ""
    }
  }), _vm.show === this.hash(JSON.stringify(_vm.params) + _vm.namespace) ? _c('div', {
    staticClass: "drawer-content"
  }, [_c('FilterTable', {
    attrs: {
      "fetch-items": function fetchItems(params) {
        return _vm.$store.dispatch("".concat(_vm.namespace, "/").concat(_vm.api[_vm.namespace]), params);
      },
      "count": true,
      "namespace": _vm.namespace,
      "export-csv": function exportCsv(params) {
        return _vm.$store.dispatch("".concat(_vm.namespace, "/exportCsv"), params);
      },
      "fetch-items-params": _vm.params,
      "columns": _vm.generateCols[_vm.namespace],
      "limit": 20,
      "search-filters": _vm.filters[_vm.namespace]
    }
  })], 1) : _vm._e()], 1), _c('SldDetails', {
    attrs: {
      "sld-data": _vm.sldData,
      "sld-open": _vm.sldOpen
    },
    on: {
      "close": _vm.closeDrawer
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }