var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('chart', {
    attrs: {
      "chartref": _vm.chartref,
      "type": _vm.type,
      "image": _vm.image,
      "datasets": _vm.linesData,
      "options": _vm.computedOptions,
      "width": _vm.width,
      "height": _vm.height
    },
    on: {
      "click": _vm.click,
      "ready": _vm.ready,
      "clickchart": _vm.clickchart
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }