<template>
  <div>
    <Drawer
      :mask-style="{background: 'rgba(180, 183, 182, 0.6)'}"
      @on-visible-change="handleVisibleChange"
      width="85%"
      :value="(show === this.hash(JSON.stringify(params) + namespace))"
      class="focus-panel"
    >
      <div class="drawer-header">
        <h3>{{ description.title }} </h3>
        <div class="widget-about">
          <div>{{ description.desc }}</div>
        </div>
        <Alert type="warning"
               style="display:block;width:100%"
               show-icon
        >
          The table may contains more data than the clicked widget because we are displaying more contextual data (eg: in widgets, trackers are not count by distinc type [pixel, indexDB, localsorage..] but they are in table details
        </Alert>
        <div class="widget-metadata">
          <br>
          <div>
            <p>
              {{ this.$t('global.report') }} <strong>{{ scopeSelectedProperty.displayName }}</strong> {{ this.$t('global.from') }}
              <strong>{{ scopeSelectedDaterange[0] }}</strong> {{ this.$t('global.to') }}
              <strong>{{ scopeSelectedDaterange[1] }}</strong>
            </p>
          </div>
        </div>
      </div>

      <Divider dashed />

      <div
        class="drawer-content"
        v-if="(show === this.hash(JSON.stringify(params) + namespace))"
      >
        <FilterTable
          :fetch-items="(params) => $store.dispatch(`${namespace}/${api[namespace]}`, params)"
          :count="true"
          :namespace="namespace"
          :export-csv="(params) => $store.dispatch(`${namespace}/exportCsv`, params)"
          :fetch-items-params="params"
          :columns="generateCols[namespace]"
          :limit="20"
          :search-filters="filters[namespace]"
        />
      </div>
    </Drawer>
    <SldDetails :sld-data="sldData"
                :sld-open="sldOpen"
                @close="closeDrawer"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import FilterTable from '@/components/ui/FilterTable'
import SldDetails from '@/views/Cookies/SldDetails'
import tableColumn from '@/mixins/tableColumn'
export default {
  components: {FilterTable, SldDetails},
  mixins: [tableColumn],
  props: {
    excludedFilters: {
      type: Array,
      default: () => []
    },
    show: {
      type: [Number, String, Boolean],
      note: 'if the drawer should be open',
      default: false
    },
    params: {
      type: Object,
      note: 'default call params',
      default: () => {}
    },
    description: {
      type: Object,
      note: 'data description',
      default: () => {}
    },
    namespace: {
      type: String,
      note: 'api name',
      required: true
    },
    api: {
      type: Object,
      note: 'the vuex actions',
      required: true
    }
  },
  data () {
    return {
      ready: false,
      sldOpen: false,
      sldData: {},
      filters: {
        cookie: ['search', 'show-category-exemption', 'isExempted'].filter( el =>  this.excludedFilters.indexOf( el ) < 0),
        vendor: ['search', 'isInCmp', 'isInAdstxt', 'isInGatp','show-category-exemption', 'isExempted'].filter( el =>  this.excludedFilters.indexOf( el ) < 0)
      }
    }
  },
  computed: {
    ...mapGetters({
      scopeSelectedDaterange: 'scope/selectedDaterange',
      scopeSelectedProperty: 'scope/selectedProperty',
      hash: 'hash',
      hasRole: 'user/hasRole',
      showCategoryHelper: 'vendor/showCategoryHelper',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      complianceColor: 'vendor/riskColor'
    }),
    generateCols () {
      const cols = {}
      if (this.namespace === 'vendor') {
        cols[this.namespace] = [
          this.createColumn(this.$t('global.table.name'), 'name', 'Vendors.name', 'vendorLink'),
          // this.createColumn(this.$t('global.table.category'), 'category.name', 'Categories.name', row => ({
          //   content: (row.category && row.category.name) ? row.category.name : 'Under qualification'
          // })),
          {
            title: this.$t('global.filters.is_exempted'),
            sortable: 'custom',
            sortKey: 'Vendors.is_exempted',
            render: (h, params) => {
              const render = h('Tag', {
                props: {
                  color: 'blue'
                }
              }, 'Exempted')
              return params.row.is_exempted === 1 ? render : ''
            }
          },
          this.doesHelperColumn(),

        
          this.createColumn(this.$t('global.table.tcf'), 'iabv2Id', 'Vendors.iabv2Id', null, 100),
          this.createColumn(this.$t('global.table.adstxt'), 'adstxd', false, row => ({
            content: row.adstxtvendors.length > 0 ? this.$t('global.filters.in') : this.$t('global.filters.out')
          })),
          this.createColumn(this.$t('global.table.cmp'), 'cmp', false, row => ({
            content: row.cmpvendors.length > 0 ? this.$t('global.filters.in') : this.$t('global.filters.out')
          })),
          this.createColumn('GATP', 'GAP', false, row => ({
            content: row.vendor_googleadtechproviders.length ? this.$t('global.filters.in') : this.$t('global.filters.out')
          })),
          this.createColumn(this.$t('global.table.country'), 'country.displayName', 'Countries.name', row => ({
            content: (row.country && row.country.displayName) ? row.country.displayName : 'Under qualification'
          }))
        ]
      }
      else {
        cols[this.namespace] = [
          {
            title: 'Name',
            key: 'Cookies.name',
            sortable: 'custom',
            sortKey: 'Cookies.name',
            render: (h, params) => {
              const t = this
              if (this.hasRole('evidence-viewer') || this.hasRole('super-admin')) {
                return h('a', {
                  on: {
                    click: () => {t.clickTracker(params.row, 'tracker')}
                  }
                }, params.row.name)
              } 
              return h('span', {}, params.row.name)
              
            }
          },
          {
            title: this.$t('global.filters.is_exempted'),
            sortable: 'custom',
            sortKey: 'Cookies.is_exempted',
            render: (h, params) => {
              const render = h('Tag', {
                props: {
                  color: 'blue'
                }
              }, 'Exempted')
              return params.row.is_exempted === 1 ? render : ''
            }
          },
          this.createColumn(this.$t('global.table.domain'), 'host', 'Cookies.host'),
          this.createColumn(this.$t('global.table.party'), 'key', 'Cookies.is_first_party', row => ({
            content: row.isFirstParty ? '1st party' : '3rd party'
          })),
          {
            title: 'Initiator',
            sortKey: 'Cookies.initiator_vendor_name_tmp',
            key: 'initiator_vendor_name_tmp',
            sortable: 'custom',
            render: (h, params) => {
                
              return params?.row?.initiator_vendor?.uuid
                ? h('router-link', {
                  props: {
                    to: `/vendors/${params.row.initiator_vendor.uuid}`
                  },
                  style: {
                    fontWeight: 'bold'
                  },
                  attrs: {
                    title: params?.row?.initiator_host
                  }
                }, params.row.initiator_vendor.name || 'N/A')
                : h('span', {
                  class:"toto",
                  attrs: {
                    title: params?.row?.initiator_host
                  }
                }, 'N/A')
            }
          },
          {
            title: 'Initiator category',
            sortKey: 'Cookies.initiator_vendor_category_tmp',
            key: 'initiator_vendor_category',
            sortable: 'custom'
          },
          this.createColumn(this.$t('global.table.vendor'), 'vendor', 'Vendors.name', row => {
            if (row.vendor && row.vendor.name) {
              return {
                tag: 'router-link',
                props: {to: `/vendors/${row.vendor.uuid}`},
                content: row => ({tag: 'strong', content: row.vendor.name})
              }
            }
            return {tag: 'span', content: (row.vendor && row.vendor.name) ? row.vendor.name : 'Under qualification'}
          }),
          this.doesHelperColumn(),
          
          
          // this.createColumn(this.$t('global.table.category'), 'categories', 'Categories.name', row => ({
          //   content: (row.vendor && row.vendor.category && row.vendor.category.name) ? row.vendor.category.name : 'Under qualification'
          // })),
         
          this.createColumn(this.$t('global.table.type'), 'type', 'Cookies.type'),
          this.createColumn(this.$t('global.table.lifetime'), 'lifetime', 'Cookies.lifetimeDays', row => ({
            content: this.getCookieAgeInMonthOrDays(row.lifetimeDays)
          })),
          {
            key: 'cmp_scenario_outcome',
            sortable: 'custom',
            sortKey: 'Cookies.cmp_scenario_outcome',
            title: 'Scenario',
            render: (h, params) => h('span', {}, params.row.cmp_scenario_outcome === 'ACCEPT' ? 'With explicit choice' : params.row.cmp_scenario_outcome === 'REJECT' ? 'Despite refusal' : 'Without explicit choice' )

          }
        ]
      }
      return cols
    }
  },
  methods: {
    ...mapActions({
      setFocus: 'scope/setFocus'
    }),
        
    clickTracker (data, context) {
      data.context = context
      this.sldData = data
      this.sldOpen = true
    },
    closeDrawer () {
      this.sldOpen = false
      this.sldData = {}
    },
    doesHelperColumn () {
      const { showCategoryHelper } = this
      const key =  this.namespace === 'vendor' 
        ? {
          title: this.$t('global.table.category'),
          sortable: 'custom',
          sortKey: 'Categories.name',
          render: (h, params) => h('span', {}, [
            h('span', {},[
              params.row.category.is_exempted ? h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}) : '']),
            (params.row && params.row.category && params.row.category.name) ? params.row.category.name : 'Under qualification'
          ])
        }
        :  {
          title: this.$t('global.table.category'),
          sortable: 'custom',
          sortKey: 'Categories.name',
          render: (h, params) => h('span', {}, [
            h('span', {},[
              (this.namespace !== 'vendor' ? params.row.vendor.category.is_exempted : params.row.category.is_exempted ) ? h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}) : '']),
            (params.row.vendor && params.row.vendor.category && params.row.vendor.category.name) ? params.row.vendor.category.name : 'Under qualification'
          ])
        }
      return showCategoryHelper 
        ? {
          key: 'Category.name',
          title: this.$t('global.table.category'),
          sortable: 'custom',
          sortKey: 'Categories.name',
          render: (h, params) => {
            if (this.namespace !== 'vendor' && !(params.row.vendor && params.row.vendor.category && params.row.vendor.category.description)) return h('span', {}, 'Under qualification')
            const content = this.namespace === 'vendor' ? params.row.category.description : (params.row.vendor && params.row.vendor.category && params.row.vendor.category.description) ? params.row.vendor.category.description : 'Under qualification'
            const name = this.namespace === 'vendor' ? params.row.category.name : (params.row.vendor && params.row.vendor.category && params.row.vendor.category.name) ? params.row.vendor.category.name : 'Under qualification'
            return h('Poptip', {
              props: {
                content: content,
                placement:"right",
                'word-wrap': true,
                style:{wordWrap: 'break-word'},
                width: 300,
                trigger:'hover'
              }
            }, [
              h('Badge', {
                slot:"title",
                props: this.namespace === 'vendor' 
                  ? {
                    color: this.complianceColor[this.vendorVigilanceToLetter(params.row.category.vigilance_id)],
                    text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.category.vigilance_id)
                  }
                  : {
                    color: this.complianceColor[this.vendorVigilanceToLetter(params.row.vendor.category.vigilance_id)],
                    text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.vendor.category.vigilance_id)
                  }
              }),
              h('span', {},[
                (this.namespace !== 'vendor' ? params.row.vendor.category.is_exempted : params.row.category.is_exempted ) ? h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}) : '',
                name
                
              ])
            ])}
        }
        : key
     
    },
    getCookieAgeInMonthOrDays (days) {
      if (days > -31 && days < 31) return days + ' ' + this.$t('global.time.days')
      const month = Math.round(days / 30.5)
      return month + ' ' + this.$t('global.time.months')
    },
    handleVisibleChange (state) {
      // Will drawer open?
      if (state) {
        return
      } else {
        this.setFocus(false)
      }
    }
  }
}
</script>
