<template lang="html">
  <div
    class="chart"
    :ref="type + datasets"
  >
    <img
      v-if="chartBase64"
      class="chart-image"
      :src="chartBase64"
    >
    <canvas
      :ref="chartref"
      :id="chartref"
      v-show="!image"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
/**
* Base component for charts, using Chart.js
*/

import merge from 'deepmerge'
import { arrayMerge } from '@/helpers/arrays'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'
import { mapGetters } from 'vuex'
import { eventBus } from '@/main'
Chart.register(zoomPlugin)


export default {
  name: 'Chart',
  data () {
    return {
      chartCanvas: null,
      maxVal: 0,
      maxAbs: 0,
      animationComplete: false
    }
  },
  props: {
    chartref: {
      type: Number,
      note: 'parent uid for ref',
      default: 0
    },
    /**
     * Chart type: 'Doughnut', 'Line'
     */
    type: {
      type: String,
      required: true
    },
    /**
     * Datasets formatted for Chart.js
     */
    datasets: {
      type: Object,
      required: true
    },
    /**
     * Overwrite and merged with default options
     */
    options: {
      type: Object,
      default: () => {}
    },
    /**
     * Canvas width (responsive so used as a ratio)
     */
    width: {
      type: Number,
      default: 16
    },
    /**
     * Canvas height (responsive so used as a ratio)
     */
    height: {
      type: Number,
      default: 9
    },
    /**
     * If true, chart is displayed as a png, not a canvas (useful for pdf)
     */
    image: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    datasets (val, old) {
      this.chartCanvas.destroy()
      return this.renderChart(val)
      // this.chartCanvas.update()

    },
    lang () {
      this.chartCanvas.update()
    }
  },
  computed: {
    genericOptions () {
      const that = this
      const opts = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, item) => {
          if (item.length <= 0) return
          const dataset = that.datasets.datasets[item[0].datasetIndex]
          const label = that.chartCanvas.tooltip.title[0] || that.chartCanvas.tooltip.body[0].lines[0]
          const data = dataset.data[item[0].index]
          this.$emit('clickchart', { label, dataset, data })
        },
        plugins: {
          zoom: {
            limits: {
              y: {min: -30, max: that.maxVal},
              x: {min: -3, max: that.maxAbs}
            },
          },
          legend: {
            /**
             * This function disable all other datasets when you click on a specific legend
             */
            onClick: (e, legendItem, legend ) => {
              var index = that.type !== 'doughnut' ? legendItem.datasetIndex : legendItem.index
              var ci = this.chartCanvas
              var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden
              const arr = that.type !== 'doughnut' ? ci.data.datasets : ci.data.datasets[0].data
              if (that.type === 'doughnut') {
                arr.forEach((elm, id) => {
                  if (index === id) {
                    ci.toggleDataVisibility(id)
                  }
                })
              } else {
                arr.forEach(function(e, i) {
                  var meta = ci.getDatasetMeta(i)
                  if (i !== index) {
                    if (!alreadyHidden) {
                      meta.hidden = meta.hidden === null ? !meta.hidden : null
                    } else if (meta.hidden === null) {
                      meta.hidden = true
                    }
                  } else if (i === index) {
                    meta.hidden = null
                  }
                })
              }
              ci.update()
            }

          },
        }

      }
      if (this.type === 'line') {
        opts.scales = {
          y: {
            suggestedMin: 1,
            suggestedMax: that.maxVal -20,
            grid: {
              drawBorder: false,
              color: function(context) {
                if (context.tick.value === 0) {
                  return '#999'
                } 
                // if (context.tick.value < 0) return 'orange'
                return '#efefef'
              }
            }
          }
        }
      }
      if (this.image) opts.animation.duration = 0
      return this.options ? merge(opts, this.options, { arrayMerge }) : opts
    },
    chartBase64 () {
      return this.chartCanvas && this.image && this.animationComplete ? this.chartCanvas.toBase64Image() : null
    }
  },
  methods: {
    renderChart (data) {
      this.chartCanvas = new Chart(this.$refs[this.chartref].getContext('2d'), {
        defaultFontColor: '#707070',
        defaultFontFamily: 'Montserrat',
        type: this.type,
        data: data,
        options: this.genericOptions
      })
    },
    setAnimationComplete () {
      this.animationComplete = true
    },
    defineHightestValue () {
      const flatArray = this.datasets.datasets.map(dataset => dataset.data).flat()
      this.maxVal = Math.max(...flatArray) + 30
      this.maxAbs = flatArray.length / this.datasets.datasets.length
    }
  },
  mounted () {
    eventBus.$on('download-chart-as-jpeg', params => {
      const ref = this.chartref
      if (params.id === ref) {
        const canvas = document.getElementById(ref).toDataURL("image/png").replace("image/png", "image/octet-stream")
        const link = document.createElement('a')
        document.getElementById(ref).appendChild(link)
        link.setAttribute('download', params.name)
        link.setAttribute('href', canvas)
        link.click()
        document.getElementById(ref).removeChild(link)
      }
    })
    this.$nextTick(() => {
      this.renderChart(this.datasets)
    })
    this.defineHightestValue()
  }
}
</script>
