var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.url !== 'TC String' ? _c('Card', [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Request")]), _c('div', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c('Button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click(e) {
        return _vm.copyJSON(e, _vm.parameters);
      }
    }
  }, [_vm._v(" Copy "), _c('span', {
    staticClass: "far fa-copy"
  })])], 1), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('Input', {
    staticClass: "request-item",
    attrs: {
      "value": _vm.url,
      "readonly": true
    }
  }), _c('div', {
    staticClass: "request-item"
  }, [_c('Table', {
    attrs: {
      "data": _vm._parameters,
      "columns": _vm.parametersTableColumns
    }
  })], 1)], 1)]) : _vm._e(), _c('Card', [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Response")]), _c('div', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c('Button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click(e) {
        return _vm.copyJSON(e, _vm.response);
      }
    }
  }, [_vm._v(" Copy "), _c('span', {
    staticClass: "far fa-copy"
  })])], 1), _c('div', {
    staticClass: "request-item",
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('vue-json-pretty', {
    attrs: {
      "data": _vm.response,
      "deep": 1
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }