<template>
  <div id="modal-del">
    <Button
      v-if="type !== 'TC'"
      size="small"
      type="text"
      @click="modalOpen = true"
    >
      <i class="far fa-file-code" />
    </Button>
    <Modal
      v-model="modalOpen"
      :title="title"
      width="90vw"
    >
      <div
        slot="header"
        v-if="_responses.length === 1"
        class="modal-header"
      >
        <p>{{ title }}</p>
      </div>
      <Scroll
        v-if="_responses.length > 1"
      >
        <Collapse
          :model="collapseValue"
          simple
        >
          <Panel
            v-for="(response, idx) in _responses"
            :key="idx"
            :name="_labels[idx]"
          >
            <h3>{{ _labels[idx] }}</h3>
            <RequestContainer
              v-if="idx < _urls.length && idx < _parameters.length && idx < _labels.length"
              :request-idx="idx"
              :url="_urls[idx]"
              :parameters="_parameters[idx]"
              :response="response"
              :label="_labels[idx]"
              slot="content"
            />
          </Panel>
        </Collapse>
      </Scroll>
      <Scroll
        v-else
        :height="560"
      >
        <RequestContainer
          :request-idx="0"
          :url="_urls[0]"
          :parameters="_parameters[0]"
          :response="_responses[0]"
          :label="_labels[0]"
        />
      </Scroll>
      <div slot="footer">
        <Button type="primary"
                @click="modalOpen = false"
        >
          Ok
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import RequestContainer from './RequestContainer'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: 'WidgetResponseModal',
  components: {
    RequestContainer
  },
  props: {
    urls: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => 'default'
    },
    parameters: {
      type: Array,
      default: () => []
    },
    responses: {
      type: Array,
      default: () => [],
      note: 'Array of response data as JSON object.'
    },
    labels: {
      type: Array,
      default: () => this.responses.map((response, idx) => `Response ${idx}`),
      note: 'Array of label to be displayed for each response'
    }
  },
  data () {
    return {
      modalOpen: false,
      collapseValue: null,
    }
  },
  computed: {
    title () {
      if (this.labels && this.labels.length === 1) {
        return `${this.labels[0]} response`
      }
      return 'Widget response'
    },
    _urls () {
      return this.urls.filter(response => !!response)
    },
    _parameters () {
      return this.parameters
        .filter(parameters => !!parameters && Object.keys(parameters).length > 0)
    },
    _responses () {
      return this.responses.filter(response => !!response && Object.keys(response).length > 0)
    },
    _labels () {
      return this.labels.filter(label => !!label)
    },
  }
}
</script>

<style lang="scss">
#modal-dev {
.panel-header {
  display: flex;
  align-items: center;
  width: 95%;

  .ivu-btn {
    margin-left: 10px;
  }
}
.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ivu-btn {
    margin-right: 30px;
  }
}
}


</style>
