var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: _vm.type + _vm.datasets,
    staticClass: "chart"
  }, [_vm.chartBase64 ? _c('img', {
    staticClass: "chart-image",
    attrs: {
      "src": _vm.chartBase64
    }
  }) : _vm._e(), _c('canvas', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.image,
      expression: "!image"
    }],
    ref: _vm.chartref,
    attrs: {
      "id": _vm.chartref,
      "width": _vm.width,
      "height": _vm.height
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }