<template lang="html">
  <chart
    :chartref="chartref"
    :type="type"
    :image="image"
    :datasets="linesData"
    :options="computedOptions"
    :width="width"
    :height="height"
    @click="click"
    @ready="ready"
    @clickchart="clickchart"
  />
</template>

<script>
/**
 * Chart component for line charts.
 */
import {colors} from '@/config'
import Color from 'color'
import merge from 'deepmerge'
import {arrayMerge} from '@/helpers/arrays'
import Chart from './Chart'
import { mapGetters } from 'vuex'

export default {
  name: 'ChartLine',
  components: {
    Chart
  },
  data () {
    return {
      maxYvalue: null
    }
  },
  props: {
    chartref: {
      type: Number,
      note: 'parent uid for ref',
      default: 0
    },
    /**
     * Datasets
     */
    datasets: {
      type: Array,
      required: true
    },
    /**
     * To display the chart as line or bar
     */
    bar: {
      type: Boolean
    },
    /**
     * To display area of color under the lines or inside the bars
     */
    fill: {
      type: Boolean,
      default: false
    },
    /**
     * Overwrite or merge with default options
     */
    options: {
      type: Object,
      default: () => {}
    },
    // /**
    //  * Display a line
    //  */
    // line: {
    //   type: Boolean
    // },
    /**
     * Canvas width (responsive so used as a ratio)
     */
    width: {
      type: Number,
      default: 400
    },
    /**
     * Canvas height (responsive so used as a ratio)
     */
    height: {
      type: Number,
      default: 250
    },
    /**
     * Array of abscissa values
     */
    labels: {
      type: Array,
      default: () => []
    },
    /**
     * Time is defined an object with one property: 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year' with a value of a format to display with momentjs cf.: https://momentjs.com/docs/#/displaying/format/
     */
    time: {
      type: Object,
      default: () => {}
    },
    /**
     * If true, chart is displayed as a png, not a canvas (useful for pdf)
     */
    image: {
      type: Boolean,
      default: false
    },
    /**
     * Array of colors applied to datasets
     */
    colors: {
      type: Array,
      default: () => colors.map(c => c.hex)
    }
  },
  mounted () {

    // const t = this.datasets
    // const allValue = []
    // t.forEach(elm => {
    //   elm.data.forEach(v => allValue.push(v))
    // });
    // const maxValue = Math.max.apply(null, allValue) + (1/100 * Math.max.apply(null, allValue))
    this.maxYvalue = null 
  },
  computed: {
    ...mapGetters({
      scopeSelectedDaterange: 'scope/selectedDaterange'
    }),
    type () {
      // return this.line ? 'LineWithLine' : this.bar ? 'bar' : 'line'
      const isUniqueValue = this.scopeSelectedDaterange[0] === this.scopeSelectedDaterange[1]
      return isUniqueValue ? 'bar' : 'line'
    },
    computedOptions () {
      let opts = {
        aspectRatio: 1,
        responsive: true,
        interaction: {
          intersect: false,},
        // scales: {
        //   y: {
        //     // The axis for this scale is determined from the first letter of the id as `'x'`
        //     // It is recommended to specify `position` and / or `axis` explicitly.
        //     type: 'logarithmic',
        //   }
        // },
        scales: {
          y: {
            suggestedMax: this.maxYvalue
          }
        },
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: this.$route.path.includes('vendors-management')  ? false : true,
              },
              pinch: {
                enabled: this.$route.path.includes('vendors-management') ? false : true,
              },
              mode: 'y',
            }
          },
        },
        layout: {
          padding: {
            bottom: 0
          }
        }
      }
      return this.options ? merge(opts, this.options, {arrayMerge}) : opts
    },
    linesData () {
      let ld = {
        datasets: this.datasets.map((d, i) => {
          if (this.bar) {
            console.log('bar')
            const lineColor = this.colors[i]
            // const bgColor = Color(lineColor).rgb().string() // .alpha(0.15).string()
            d.borderColor = lineColor
            console.log(lineColor)
            d.borderWidth = 2
            d.backgroundColor = lineColor
            d.fill = this.fill
          } else {
            console.log('line')
            const lineColor = this.colors[i]
            const bgColor = Color(lineColor).rgb().string() // .alpha(0.15).string()
            d.borderColor = lineColor
            d.borderWidth = 2
            d.backgroundColor = bgColor
            d.fill = this.fill
          }
          d.tension = 0.3
          return d
        })
      }
      console.log(ld)
      if (this.time) {
        const datalabels = this.datasets.map(d => {
          const data = d.data
          return data.map(v => v.t)
        })
        const timeLabels = [...new Set([].concat.apply([], datalabels))]
        ld.labels = timeLabels.map(d => this.moment(d))
      } else if (this.labels) {
        ld.labels = this.labels
      }
      return ld
    }
  },
  methods: {
    defineMaxY (elm){
    },

    click (data) {
      /**
       * Returns { type: 'data', label, value, dataset } or { type: 'dataset', dataset }
       *
       * @event click
       * @type {object}
       */
      console.log('héé')
      this.$emit('click', data)
    },
    ready () {
      /**
       * Emited when animations are complete, useful to know when png version is available in the DOM
       *
       * @event ready
       * @type {event}
       */
      this.$emit('ready')
    },
    clickchart (data) {
      this.$emit('clickchart', data)
    }
  }
}
</script>

<style lang="scss">
.container-text {
  display: flex;
  width: 100%;

  span {
    margin: 0 5px;
    font-weight: 500;
  }
}

.txt-title {
  color: #fff
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
</style>
