var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "modal-del"
    }
  }, [_vm.type !== 'TC' ? _c('Button', {
    attrs: {
      "size": "small",
      "type": "text"
    },
    on: {
      "click": function click($event) {
        _vm.modalOpen = true;
      }
    }
  }, [_c('i', {
    staticClass: "far fa-file-code"
  })]) : _vm._e(), _c('Modal', {
    attrs: {
      "title": _vm.title,
      "width": "90vw"
    },
    model: {
      value: _vm.modalOpen,
      callback: function callback($$v) {
        _vm.modalOpen = $$v;
      },
      expression: "modalOpen"
    }
  }, [_vm._responses.length === 1 ? _c('div', {
    staticClass: "modal-header",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm._responses.length > 1 ? _c('Scroll', [_c('Collapse', {
    attrs: {
      "model": _vm.collapseValue,
      "simple": ""
    }
  }, _vm._l(_vm._responses, function (response, idx) {
    return _c('Panel', {
      key: idx,
      attrs: {
        "name": _vm._labels[idx]
      }
    }, [_c('h3', [_vm._v(_vm._s(_vm._labels[idx]))]), idx < _vm._urls.length && idx < _vm._parameters.length && idx < _vm._labels.length ? _c('RequestContainer', {
      attrs: {
        "slot": "content",
        "request-idx": idx,
        "url": _vm._urls[idx],
        "parameters": _vm._parameters[idx],
        "response": response,
        "label": _vm._labels[idx]
      },
      slot: "content"
    }) : _vm._e()], 1);
  }), 1)], 1) : _c('Scroll', {
    attrs: {
      "height": 560
    }
  }, [_c('RequestContainer', {
    attrs: {
      "request-idx": 0,
      "url": _vm._urls[0],
      "parameters": _vm._parameters[0],
      "response": _vm._responses[0],
      "label": _vm._labels[0]
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.modalOpen = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }